import arrowDownOutline from '../img/12-arrow-down-outline.png';
import arrowDownOutline2 from '../img/34-code-outline.gif';
import arrowDownOutline3 from '../img/38-error-cross-simple-outline.gif';
import arrowDownOutline4 from '../img/48-favorite-heart-solid.png';
import arrowDownOutline5 from '../img/48-plus-to-square-rotation-outline.gif';
import arrowDownOutline6 from '../img/128-cloud-done-outline.gif';
import arrowDownOutline7 from '../img/140-earphone-telephone-outline.gif';
import arrowDownOutline8 from '../img/160-trenging-up-outline.gif';
import arrowDownOutline9 from '../img/207-chat-message-plus-outline.gif';
import arrowDownOutline10 from '../img/319-menu-6-morph-outline.png';
import arrowDownOutline11 from '../img/319-menu-6-morph-outline-blue.png';
import arrowDownOutline12 from '../img/1141-email-outline.gif';
import arrowDownOutline13 from '../img/5305153_fb_facebook_facebook logo_icon.svg';
import arrowDownOutline14 from '../img/5305157_connection_linkedin_network_linkedin logo_icon.svg';
import arrowDownOutline15 from '../img/5305170_bird_social media_social network_tweet_twitter_icon.svg';
import arrowDownOutline16 from '../img/5335781_camera_instagram_social media_instagram logo_icon.svg';


import pic1 from '../img/android-chrome-192x192.png';
import pic2 from '../img/android-chrome-512x512.png';
import pic3 from '../img/apple-touch-icon.png';
import pic4 from '../img/arrow-up.png';
import pic6 from '../img/logo-blue-g.png';
import pic7 from '../img/logo-white-g.png';
import pic8 from '../img/logo-white.png';
import pic9 from '../img/favicon.ico';
import pic10 from '../img/favicon-16x16.png';
import pic11 from '../img/favicon-32x32.png';
import pic13 from '../img/dn-1.jpg';
import pic14 from '../img/dn-top.jpg';
import pic15 from '../img/mstile-150x150.png';
import pic16 from '../img/project.jpg';
import pic17 from '../img/safari-pinned-tab.svg';
import pic18 from '../../../../../../Public/site.webmanifest';

const projectHTMLCluster = document.getElementById("projectCluster");
const projectHTMLTemplate = document.getElementById("projectTemplate");
const portfolioSortSelect = document.getElementsByName("portfolioSort")[0];
const portfolioOrderSelect = document.getElementsByName("portfolioOrder")[0];
const projectSkillButtonHTMLCluster = document.getElementById("projectSkillButtonCluster");
const projectsLoadMoreHTML = document.getElementById("projectsLoadMore");
const skillsHTMLCluster = document.getElementById("skills");
let showProjectCount = 6;


function getNormalizeDuration(duration, startedYear) {
    if (duration === -1) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        return ((currentYear - startedYear) * 12) + (currentMonth - 6);
    } else {
        return duration;
    }
}

function compareByDurationAsc(a, b) {
    let compareADuration = getNormalizeDuration(a.durationMonths, a.startedYear);
    let compareBDuration = getNormalizeDuration(b.durationMonths, b.startedYear);

    if (compareADuration > compareBDuration) {
        return 1;
    }
    if (compareADuration < compareBDuration) {
        return -1;
    }
    return 0;
}

function compareByDurationDesc(a, b) {
    let compareADuration = getNormalizeDuration(a.durationMonths, a.startedYear);
    let compareBDuration = getNormalizeDuration(b.durationMonths, b.startedYear);

    if (compareADuration > compareBDuration) {
        return -1;
    }
    if (compareADuration < compareBDuration) {
        return 1;
    }
    return 0;
}

function compareByStartedYearAsc(a, b) {
    if (a.startedYear > b.startedYear) {
        return 1;
    }
    if (a.startedYear < b.startedYear) {
        return -1;
    }
    return 0;
}

function compareByStartedYearDesc(a, b) {
    if (a.startedYear > b.startedYear) {
        return -1;
    }
    if (a.startedYear < b.startedYear) {
        return 1;
    }
    return 0;
}

function skillsMatchActiveSkills(skills) {
    if (activeSkills.length === 0)
        return true;

    let completeFound = true;
    for (let i = 0; i < activeSkills.length; i++) {
        let found = false;
        for (let j = 0; j < skills.length; j++) {
            if (activeSkills[i] === skills[j].shortType) {
                found = true;
                break;
            }
        }
        if (!found) {
            completeFound = false;
            break;
        }
    }
    return completeFound;
}

function showProjectsByFilter(withoutLimit = false) {
    clearProjectsFromView();

    switch (portfolioSortSelect.value) {
        case 'duration':
            if (portfolioOrderSelect.value === "asc") {
                projects.sort(compareByDurationAsc);
            } else {
                projects.sort(compareByDurationDesc);
            }
            break;
        default:
        case 'startingYear':
            if (portfolioOrderSelect.value === "asc") {
                projects.sort(compareByStartedYearAsc);
            } else {
                projects.sort(compareByStartedYearDesc);
            }
            break;
    }

    let shownProjects = 0;
    let viewableProjects = 0;
    for (let i = 0; i < projects.length; i++) {
        if (skillsMatchActiveSkills(projects[i].skills)) {
            if (showProjectCount > shownProjects || withoutLimit) {
                addProjectToView(projects[i], shownProjects + 1);
                shownProjects++;
            }
            viewableProjects++;
        }
    }

    if (viewableProjects > shownProjects) {
        projectsLoadMoreHTML.style.display = "flex";
    } else {
        projectsLoadMoreHTML.style.display = "none";
    }
}

function clearProjectsFromView() {
    for (let i = projectHTMLCluster.childNodes.length - 1; i >= 0; i--) {
        if (projectHTMLCluster.childNodes[i].id === 'projectTemplate')
            continue;

        projectHTMLCluster.removeChild(projectHTMLCluster.childNodes[i]);
    }
}

function getSkillsAsString(skills) {
    let out = "";
    for (let i = 0; i < skills.length; i++) {
        if (out !== '')
            out += ', ';
        out += skills[i].type;
    }

    return out;
}

function addProjectToView(project, idx) {
    const newProjectHTML = projectHTMLTemplate.cloneNode(true);

    newProjectHTML.setAttribute("id", "project" + idx)
    newProjectHTML.getElementsByClassName("projectName")[0].innerText = project.name + ' - ' + project.client;
    newProjectHTML.getElementsByClassName("projectDescription")[0].innerText = project.description;
    newProjectHTML.getElementsByClassName("projectStartedYear")[0].innerText = project.startedYear;

    let numMonths = getNormalizeDuration(project.durationMonths, project.startedYear);
    let duration = "";
    if (numMonths > 11) {
        const years = Math.floor(numMonths / 12);
        numMonths -= years * 12;
        duration = years + (years === 1 ? ' Jahr ' : ' Jahre ');
    }
    if (numMonths > 0) {
        duration += numMonths + (numMonths === 1 ? ' Monat' : ' Monate')
    }
    newProjectHTML.getElementsByClassName("projectDuration")[0].innerText = duration;
    newProjectHTML.getElementsByClassName("projectSkills")[0].innerText = getSkillsAsString(project.skills);

    newProjectHTML.style.display = "block";

    projectHTMLCluster.appendChild(newProjectHTML);
}

const activeSkills = [];

function isSkillActive(skill) {
    let found = false;
    activeSkills.forEach((s) => {
        if (s === skill)
            found = true;
    });

    return found;
}

function addSkill(skillName) {
    activeSkills.push(skillName);
}

function removeSkill(skillName) {
    for (let i = 0; i < activeSkills.length; i++) {
        if (activeSkills[i] === skillName) {
            activeSkills.splice(i, 1);
            break;
        }
    }
}

function deactivateSkillButton(htmlElement) {
    htmlElement.classList.remove("bg-aqua");
    htmlElement.classList.remove("text-white");
    htmlElement.classList.add("bg-[#ffffff]");
    htmlElement.classList.add("text-aqua");
}

function activateSkillButton(htmlElement) {
    htmlElement.classList.remove("bg-[#ffffff]");
    htmlElement.classList.remove("text-aqua");
    htmlElement.classList.add("bg-aqua");
    htmlElement.classList.add("text-white");
}

function clickSkill(pEvent) {
    const skill = pEvent.target.innerText;

    if (isSkillActive(skill)) {
        deactivateSkillButton(pEvent.target);
        removeSkill(skill);
    } else {
        activateSkillButton(pEvent.target);
        addSkill(skill);
    }
    showProjectsByFilter();
}

function deselectAllSkills() {
    for (let i = 0; i < activeSkills.length; i++) {
        removeSkill(activeSkills[0]);
    }
    for (let i = 0; i < projectSkillButtonHTMLCluster.childNodes.length; i++) {
        const node = projectSkillButtonHTMLCluster.childNodes[i];
        if (node.type === "button") {
            deactivateSkillButton(node);
        }
    }
}

function switchSkillAndScroll(pEvent) {
    let target = pEvent.target;

    if (target.tagName.toLowerCase() === "a")
        target = pEvent.target.getElementsByTagName("span")[0];

    const skill = target.innerText;

    deselectAllSkills();

    for (let i = 0; i < projectSkillButtonHTMLCluster.childNodes.length; i++) {
        const node = projectSkillButtonHTMLCluster.childNodes[i];
        if (node.type === "button" && node.innerText === skill) {
            activateSkillButton(node);
            break;
        }
    }
    addSkill(skill);

    showProjectsByFilter();
}

export function loadPortfolio() {
    if (portfolioSortSelect === undefined)
        return;

    portfolioSortSelect.onchange = () => {
        showProjectsByFilter();
    };
    portfolioOrderSelect.onchange = () => {
        showProjectsByFilter();
    };
    projectsLoadMoreHTML.onclick = () => {
        showProjectsByFilter(true);
    };

    for (let i = 0; i < projectSkillButtonHTMLCluster.childNodes.length; i++) {
        const node = projectSkillButtonHTMLCluster.childNodes[i];
        if (node.type === "button") {
            node.addEventListener("click", clickSkill);
        }
    }

    const skillPortfolioLinks = skillsHTMLCluster.getElementsByClassName("portfolioLink");
    for (let i = 0; i < skillPortfolioLinks.length; i++) {
        const link = skillPortfolioLinks[i];
        link.addEventListener("click", switchSkillAndScroll);
    }

    showProjectsByFilter();
}


function openModal(modalId) {
    const overlay = document.getElementById('modal-overlay');
    overlay.style.display = "block";

    const modal = document.getElementById(modalId);
    modal.style.display = "block";
}

function closeModal(modalId) {
    const overlay = document.getElementById('modal-overlay');
    overlay.style.display = "none";

    const modal = document.getElementById(modalId);
    modal.style.display = "none";
}

window.openModal = (modalId) => openModal(modalId);
window.closeModal = (modalId) => closeModal(modalId);

let sendContactRequestWaitDots = '';
let sendContactRequestWaitCounter

function sendContactRequest(privacy, firstName, lastName, eMail, phoneNumber, subject, message, captcha) {
    if(!privacy){
        document.getElementById('btnContactSubmit').value = "Datenschutzerklärung muss bestätigt werden!";
        return;
    }

    const xhttp = new XMLHttpRequest();

    document.getElementById('btnContactSubmit').value = "Wird gesendet";
    document.getElementById('btnContactSubmit').enabled = false;
    sendContactRequestWaitCounter = setTimeout(() => {
        if (sendContactRequestWaitDots.length === 3) {
            sendContactRequestWaitDots = '';
        } else {
            sendContactRequestWaitDots += '.';
        }
        document.getElementById('btnContactSubmit').value = "Wird gesendet" + sendContactRequestWaitDots;
    }, 1000);

    xhttp.open("POST", "/api/landingpage/contact", true);
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4){
            clearTimeout(sendContactRequestWaitCounter);
            document.getElementById('btnContactSubmit').enabled = true;

            if(this.status === 200) {
                document.getElementById('btnContactSubmit').value = "Abgesendet";
            } else {
                document.getElementById('btnContactSubmit').value = "Fehler beim Absenden";
            }

            setTimeout(() => {
                document.getElementById('btnContactSubmit').value = "Nachricht abschicken";
            }, 3000);
        }
    };

    const postData = {
        firstName, lastName, eMail, phoneNumber, subject, message, captcha
    };
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=utf-8')
    xhttp.send(JSON.stringify(postData));
}

window.sendContactRequest = (privacy, firstName, lastName, eMail, phoneNumber, subject, message, captcha) => sendContactRequest(privacy,firstName, lastName, eMail, phoneNumber, subject, message, captcha);

window.onload = () => loadPortfolio();
